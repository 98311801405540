












import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import { BlogFilter } from 'client-website-ts-library/filters';
import { WebsiteLevel } from 'client-website-ts-library/types';
import Blogs from '../components/Blogs.vue';

@Component({
  components: {
    Blogs,
  },
})
export default class Blog extends Mixins(View) {
  private filter = new BlogFilter({
    SearchLevel: WebsiteLevel.Profile,
    SearchGuid: '0b2007c3-0227-478c-97c1-29a5347fab9e',
  });
}
